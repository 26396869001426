import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./Features.css"
import { Typography, Grid, Container } from "@material-ui/core"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

 
const VielPlatz = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "NetzfachNeu3.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}

    
    render={data => 
      <div style={{paddingBottom: "20px"}} id="vielPlatz">
                  <Container maxWidth="md">
            <Grid container tem xs={12} spacing={1} direction="column" alignItems="center" justify= "center">

            <Img style={styles} fluid={data.placeholderImage.childImageSharp.fluid} />

        <Typography variant="subtitle1" align="left" paragraph>
              Netzfach
                </Typography>
        
        </Grid>
          </Container>
      
      
    </div>
    }
  />
  
)

const styles = {
width: "150px",
height: "150px",
borderRadius: "50%",
};

export default VielPlatz
