import React from "react";
import PropTypes from 'prop-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link'

const styles = theme => ({
  mailForm: {
    textAlign: 'center',
  },
  emailInput :{
    backgroundColor: 'white',
    width: '300px',
  }
});

class MailChimpForm extends React.Component {
  constructor() {
    super();
    this.state = {email: null, disbaled: false};
  }


  handleChange = (event) => {
      this.setState({email: event.target.value});
      this.setState({ disabled: true })
  }
  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:

  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).

  // 1. via `.then`
  _handleSubmit = e => {
    window && window._paq ? window._paq.push(['trackEvent', 'Interaktionen', 'MailChimp', "Button clicked" ]) : console.log("Failed to push event")
    
    e.preventDefault();
    addToMailchimp(this.state.email) // listFields are optional if you are only capturing the email address.
    .then(data => {
      window && window._paq ? window._paq.push(['trackEvent', 'Interaktionen', 'MailChimp', "Erfolgreich" ]) : console.log("Failed to push event")
      this.setState({ disabled: !this.state.disabled })
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      console.log(data)
    })
    .catch(() => {
      // unnecessary because Mailchimp only ever
      // returns a 200 status code
      // see below for how to handle errors
    })
  }

  render () {
    const { classes } = this.props;

    return (
      <form className={classes.mailForm} onSubmit={this._handleSubmit}>

        <TextField
        required
          id="outlined-email-input"
          label="Email"
          type="email"
          name="email"
          autoComplete="email"
          margin="normal"
          variant="outlined"
          onChange={this.handleChange}
        />
        <br/>
        <Typography variant="caption">
        Du kannst den Newsletter jederzeit wieder abbestellen, indem du auf den Link in der Fußzeile unserer E-Mails klickst.
        Für weitere Informationen über unsere Datenschutzpraktiken schaue in unsere AGBs.
        Wir benutzen Mailchimp als unsere E-Mail Marketing Plattform. 
        Indem du unten auf Anmelden klickst, erklärst du dich damit einverstanden, dass deine Daten zur Bearbeitung an Mailchimp weitergeleitet werden.
   </Typography>
        <br></br>
        <Link variant="caption"  color="secondary" href="https://mailchimp.com/legal/"> 
        Erfahre hier mehr über die Datenschutzpraktiken von Mailchimp.
        </Link>
        <br/>
        <br/>
        <Button disabled={!this.state.disabled} variant="contained" color="primary" label="Submit" type="submit">
          Anmelden
        </Button>
      </form>
    )
  }
}

MailChimpForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MailChimpForm);