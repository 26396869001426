import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./Janajohannes.css"
import { Typography, Grid, Container } from "@material-ui/core"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Idee = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Idea.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => 
      <div id="idee">
                  <Container maxWidth="md" height="600px">
            <Grid container spacing={2} direction="row" alignItems="center" justify= "center">
            <Grid item xs={12} sm={6}>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
        </Grid>
        <Grid container item xs={12} sm={6} spacing={3} direction="row" alignItems="center" justify= "center">
          
              <Typography variant="subtitle1" align="left" paragraph>
              Aus reiner Unzufriedenheit und dem typischen "Das muss doch besser gehen"-Denken ist dieses Start-up entstanden. 
              Doch eine Tasche zu entwickeln hat so seine Hürden, besonders wenn man nicht aus der Branche kommt. 
              Von einfachen Laien-Skizzen bis hin zur fertigen Tasche war es ein jahrelanger Prozess. 
              Doch nun ist es so weit, nach mehreren Prototypen können wir nun stolz das erste Modell präsentieren. 
              Wir freuen uns, nach und nach mehr darüber bekannt geben zu dürfen. 
              Eins steht jedoch fest: Dieses Modell ist nicht nur eine Tasche für die Sportler unter euch. 
              Denn Jana genießt lieber mal einen Tag am See, als mehrfach wöchentlich ins Fitnessstudio zu hechten. 
              Damit ist die Tasche auch für die Freizeit der perfekte Begleiter. 
              Bei Johannes steht Sport allerdings fest im Wochenprogramm und am Besten ist (in der Tasche) auch noch Platz für ein Saunahandtuch. 
              Dank unserer Tasche kann er nun endlich alles einpacken was er (für das wöchentliche Workout) braucht. 
              Mit vielen Funktionen, Variationen und feinen Extras passt sich die Tasche an dich und dein Leben ideal an. 
              Wenn du also keine 0815-Tasche suchst, bist du hier goldrichtig.

                  </Typography>
  
      
          </Grid>
        </Grid>
          </Container>
      
      
    </div>
    }
  />
)
export default Idee
