import React from "react";
import MailChimpForm from "../components/form";
import SEO from "../components/seo";
import Layout from "../components/layout";

import CookieConsent from "react-cookie-consent"

import "./bootstrap.min.css"
import "./now-ui-kit.css"

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import { createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import JanaJohannes from '../components/JanaJohannes';
import Postkarte from '../components/Postkarte'
import Instagram from '../components/Instagram'
import Facebook from '../components/Facebook'
import Schuhfach from '../components/Schuhfach'
import Kartenfach from '../components/Kartenfach'
import Duschutensilien from '../components/Duschutensilien'
import Kuehltasche from '../components/Kuehltasche'
import Fachaufteilung from '../components/Fachaufteilung'
import Flaschenhalterung from '../components/Flaschenhalterung'
import Geheimfach from '../components/Geheimfach'
import Rucksack from '../components/Rucksack'
import Zeitungsfach from '../components/Zeitungsfach'
import Magnetverschluss from '../components/Magnetverschluss'
import VielPlatz from '../components/VielPlatz'
import Kickstarter from '../components/Kickstarter'


import Idee from '../components/Idee';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },

  features: {
    backgroundColor: '#fb8c00',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  featureshell: {
    backgroundColor: 'white',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  logo: {
    maxWidth: '400px',
    margin: 'auto',
    paddingTop: '6rem',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const theme = createMuiTheme({
      palette: {
        primary: {
          main:'#FFFFFF',
          contrastText: "#54585a" //button text white instead of black
        },
        secondary: {
          main: '#ff6900',
        },
      },
    });

function IndexPage () {
  const classes = useStyles();

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Geschwister Albers" 
        description="Erfahre mehr über die moderne und multifunktionale Sport- und Freizeittasche. Dank viel Liebe zum Detail, sogar individualisierbar." 
        keywords={[`Geschwister`, `Albers`, `Sporttaschen`, `Sporttasche`, `Sport`, `Bag`,`Sportsbags`, `Freizeittasche`]} />
        
        <div id="kickstarter"  style={{paddingTop: "70px"}}>
          <div className={classes.featureshell}> 
        
             <Kickstarter></Kickstarter>

          </div>
        </div>

<div style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
        <div id="willkommen" className={classes.features}> 
               <Postkarte></Postkarte>
        </div>
        </div>


        <div id="wir" className="col-12" style={{paddingTop: "70px"}}>
          <div className={classes.featureshell}> 
          <h2 ><center>Über Uns</center></h2>
          <Container maxWidth="md" height="800px">
             <JanaJohannes></JanaJohannes>
          </Container>
          </div>
        </div>
      

        <div id="tasche" className="col-12" style={{paddingTop: "70px"}}>
        <div className={classes.features}> 
        <h2><center>Unsere Vision</center></h2>
        <Container maxWidth="md" height="600px">
            <Idee></Idee>
        </Container>
        </div>

        <div className={classes.featureshell} style={{paddingTop: "70px"}}> 
        <h2><center>Was macht unsere Tasche so besonders?</center></h2>
        <Container maxWidth="md" height="600px">
            <Grid container item xs={12} spacing={3} direction="row" alignItems="center" justify= "center">
          
            <Typography variant="subtitle1" align="left" paragraph>
            Du denkst dir sicherlich: Moment mal, Taschen gibt es doch wie Sand am Meer auf dem Markt, was macht ihr anders? 
            (Na dann pass gut auf.) Hier kommen vier gute Gründe, die für unsere Tasche sprechen: 

               </Typography>
              <Typography>
              1. Unsere Tasche ist mit Liebe zum Detail entwickelt worden.
                </Typography>
              <Typography>
              2. Die Produktion ist hochwertig und fair. Keine Ausbeutung und Massenfertigung in Billiglohnländern.
               </Typography>
              <Typography>
              3. Du kannst deine Tasche ganz nach deinen eigenen Wünschen designen.
                </Typography>
              <Typography>
              4. Unsere Tasche hält eine durchdachte Fächeraufteilung für dich bereit. Genug Platz für alles - außer Chaos!
                </Typography>

        </Grid>
        </Container>
        </div>
        <div className={classes.features}> 
        <h2><center>Die Besonderheiten unserer Tasche im Detail</center></h2>
        <Typography style={{paddingBottom: "30px"}} align="center" >Hier siehst du einen Überblick unserer einzigartigen Sport- und Freizeittasche.</Typography>
        <Container style={{paddingTop: "30px"}} maxWidth="md" height="600px">
            <Grid p={20} container item xs={12} spacing={4} direction="row" alignItems="center" justify= "center">
        <Schuhfach></Schuhfach>

        <Kartenfach></Kartenfach>
    
        <Kuehltasche></Kuehltasche>

        <Duschutensilien></Duschutensilien>
     
        </Grid>
        </Container>

        <Container style={{paddingTop: "30px"}} maxWidth="md" height="600px">
            <Grid p={20} container item xs={12} spacing={4} direction="row" alignItems="center" justify= "center">
 
            <Fachaufteilung></Fachaufteilung>
        <Flaschenhalterung></Flaschenhalterung>

        <Geheimfach></Geheimfach>
        </Grid>
        </Container>

        <Container style={{paddingTop: "30px"}} maxWidth="md" height="600px">
            <Grid p={20} container item xs={12} spacing={4} direction="row" alignItems="center" justify= "center">

        <Magnetverschluss></Magnetverschluss>
            <Rucksack></Rucksack>
            <Zeitungsfach></Zeitungsfach>
            <VielPlatz></VielPlatz>


        </Grid>
        </Container>
        </div>

        <div className={classes.features}> 
        <Container style={{paddingTop: "30px"}} maxWidth="md" height="600px">
            <Grid p={20} container item xs={12} spacing={4} direction="row" alignItems="center" justify= "center">

        </Grid>
        </Container>
        </div>
              

        <div className={classes.featureshell}> 
        <h2><center>Was ist alles farblich individualisierbar?</center></h2>
        <Container maxWidth="md" height="600px">
            <Grid container item xs={12} spacing={4} direction="row" alignItems="center" justify= "center">
            <List>
            <ListItem>
                  <ListItemText
                    primary="Taschenfarbe"
                  />
                </ListItem>
              <ListItem>
                  <ListItemText
                    primary="Gurtfarbe"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Reißverschlussfarbe"
                  />
                </ListItem>
          </List>
        </Grid>
        </Container>
        </div>


        </div>
        <div id="folgeUns" className="col-12" style={{paddingTop: "70px"}}>
        <div className={classes.features}> 
        <Container maxWidth="md" height="600px">
            <Grid container item xs={12} spacing={3} direction="row" alignItems="center" justify= "center">
            <h2><center>Folge Uns</center></h2>
        <Typography variant="subtitle1" align="center" paragraph>
        Folge uns auf Instagram und Facebook. 
        So bekommst du immer mit, was bei uns gerade passiert. 
        Auf unseren Accounts verraten wir demnächst schon die ersten Details.
        </Typography>
<Instagram>
</Instagram>
<Facebook></Facebook>

        </Grid>
        </Container>
        </div> 
        </div>
        <div id="newsletter" className="col-12" style={{paddingTop: "70px"}}>
        <div className={classes.heroContent}>
        <h2><center>Newsletter</center></h2>
        <Typography variant="subtitle1" align="center" paragraph>
        Abboniere unseren Newsletter und erfahre die News direkt aus erster Hand!
        </Typography>
          <Container maxWidth="md" >
        
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                <MailChimpForm/>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        </div>
        <div>
        <CookieConsent
          location="bottom"
          buttonText="Einverstanden"
          cookieName="cookieConsent"
          style={{ background: "grey"}}
          buttonStyle={{ color: "#53565A", fontSize: "16px", background: "#FFFFFF" }}
          onAccept={() => {window && window._paq ? window._paq.push(['rememberConsentGiven']) : console.log("Cannot push consent")}}
          >
          Wir setzen Cookies zur Verbesserung der Benutzer-Erfahrung ein.{" "}
          <span><a href="/datenschutz">Mehr erfahren</a></span>
        </CookieConsent>
        </div>
        </Layout>
        </ThemeProvider>
      </React.Fragment>
  )
};

export default IndexPage;
