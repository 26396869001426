import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./Janajohannes.css"
import { Typography, Grid } from "@material-ui/core"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const JanaJohannes = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Team_ausgeschnitten2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => 
      <div id="janajohannes">
      
            <Grid container spacing={2} direction="row" alignItems="center" justify= "center">
            <Grid container item xs={12} sm={6} spacing={3} direction="row" alignItems="center" justify= "center">
          
          <Typography variant="subtitle1" align="left" paragraph>
          Wir sind Jana & Johannes und unser Start-up-Name entspricht der Realität, denn wir sind tatsächlich Geschwister. 
          Seit Tag 1 sind wir ein unschlagbares Team. 
          Na ja, zumindest seit dem Jana auf der Welt ist, denn ein gewisser Altersunterschied trennt uns dann doch. 
          Als gelernter Wirtschaftsinformatiker (Johannes) und Digital Media Marketing Absolventin (Jana) ist dieses Start-up (für uns) doch eine ganz andere Branche.
          Für uns bedeutet das: Wir müssen uns in ein ganz neues Metier einarbeiten. Aber das macht uns unglaublich viel Spaß!
          Wir hoffen, dass wir euch an dieser Freude teilhaben lassen und euch mit unserer Tasche begeistern können.
              </Typography>

  
      </Grid>
            <Grid item xs={12} sm={6}>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
        </Grid>
       
        </Grid>
  
      
      
    </div>
    }
  />
)
export default JanaJohannes
