import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./Postkarte.css"
import { Grid, Container } from "@material-ui/core"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Postkarte = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Header_final.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => 
      <div id="postkarte">
                  <Container maxWidth="md" height="400px">
            <Grid container direction="row" alignItems="center" justify= "center">
            <Grid item xs={12}>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
        </Grid>
       
        </Grid>
          </Container>
      
      
    </div>
    }
  />
)
export default Postkarte
