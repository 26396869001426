import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import "./Features.css"
import { Grid, Container } from "@material-ui/core"

import IconButton from '@material-ui/core/IconButton';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

 
const Facebook= () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Facebook_weiß_groß.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}

    
    render={data => 
      <div id="Facebook">
                  <Container maxWidth="md" height="600px">
            <Grid container item xs={12} spacing={3} direction="column" alignItems="center" justify= "center">
            <Link href="https://www.facebook.com/Geschwister-Albers-112120783984070" target="_blank" rel="noopener"> 
            <IconButton color="primary" aria-label="instagram" component="span">
            <Img style={styles} fluid={data.placeholderImage.childImageSharp.fluid} />

        </IconButton>
        </Link>
           
      
        </Grid>
          </Container>
      
      
    </div>
    }
  />
  
)

const styles = {
width: "150px",
height: "150px",
borderRadius: "50%",
};

export default Facebook
